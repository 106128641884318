import React, { useEffect } from 'react'

// import Layout from '../containers/Layout'

import { connect } from 'react-redux';
import { setIsMapView, setIsCategoryView } from '../state/actions'
import { Wrapper, Right, Left, ButtonCallToAction, ButtonMore, HomeContent, SubSubHeadline,  SubHeadline, MainHeadline, Dog, Park, Balloon, Trumpet, Woman, Coffee, Heilandskreuz, Markthalle, SportyMan, HandEye, Map } from './homeStyled'

const Home = (props) => {
  const { 
    setIsMapView,
    setIsCategoryView,
    browserHeight
  } = props;

  const womanRef = React.createRef(null);
  const contentRef = React.createRef(null);

  useEffect(() => {
    if (browserHeight) {
      if(womanRef && womanRef.current && contentRef && contentRef.current) {
        const {offsetTop} = womanRef.current;
        const {offsetHeight} = womanRef.current;
        contentRef.current.style.marginTop = `${offsetTop + offsetHeight + 73  }px`;
      }
    }
  })

  return (
    <Wrapper>
      <Markthalle><div className="inner" /></Markthalle>
      <SportyMan><div className="inner" /></SportyMan>
      <HandEye><div className="inner" /></HandEye>
      <Heilandskreuz><div className="inner" /></Heilandskreuz>
      <Coffee><div className="inner" /></Coffee>
      <Woman ref={womanRef}><div className="inner" /></Woman>
      <Map><div className="inner" /></Map>
      <Trumpet><div className="inner" /></Trumpet>
      <Balloon><div className="inner" /></Balloon>
      <Park><div className="inner" /></Park>

      
      <MainHeadline>Die Moabiter Insel</MainHeadline>
      <SubHeadline>Entdecke deine Nachbarschaft in Moabit</SubHeadline>
      <ButtonMore to='/kiezfest/' title='Erfahre mehr'>Erfahre mehr</ButtonMore>

      <HomeContent ref={contentRef}>
        <Left>
          <SubSubHeadline>Das Moabiter Kiezfest 2020 geht online!</SubSubHeadline>
          <Dog><div className="inner" /></Dog>
        </Left>
        <Right>
          <p>Seit 2013 feiern lokale Akteur_innen aus den Bereichen Gastronomie, Kunsthandwerk, Gewerbe und Kultur und Soziales das Moabiter Kiezfest, ein beliebtes Stadtteilfest rund um das Rathaus Tiergarten. Hier präsentieren die Teilnehmenden auf einem Markt und in einem abwechselungsreichen Bühnenprogramm den zahlreichen Besucher_innenihre Projekte, Ideen und Produkte.</p>
          <p>Auf Grund der Maßnahmen zur Eindämmung des Covid19-Virus kann das Moabiter Kiezfest 2020 nicht in gewohnter Form stattfinden. Dennoch wollen wir auch in diesem Jahr gemeinsam das bunte Gesicht Moabits nach außen tragen und den lokalen Akteur_innen die Möglichkeit geben mit Interessierten und den Bewohner_innen in Kontakt zu treten.</p>
          <p>Mit der Webseite moabiterinsel.de geht das Moabiter Kiezfest online und vereint die lokalen Akteur_innen im Netz: Auf einer interaktiven Karte können Sie den Kiez erkunden, die diversen Akteur_innen kennenlernen und mithilfe von persönlichen Portraits mehr über Ihr Lieblingscafe oder -schneider erfahren!</p>
          <p>Wir wünschen viel Spaß beim Erkunden!</p>
          <p>
            <ButtonCallToAction
              title="Zur Karte"
              to='/erkunden/'
              onClick={() => {
                setIsCategoryView(true)
                setIsMapView(true)}}
            >
              Entdecke alle Akteure_innen 
              <span className="mi-arrow-cta" />
            </ButtonCallToAction>
          </p>
        </Right>
      </HomeContent>


    </Wrapper>
  )
}


const mapStateToProps = state => {
  return {
    browserWidth : state.responsive.innerWidth,
    browserHeight : state.responsive.innerHeight
  }
}

const mapDispatchToProps = {
  setIsMapView,
  setIsCategoryView
};



export default connect(mapStateToProps, mapDispatchToProps)(Home)